import { useCallback, useEffect } from 'react';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setIntercomInitialized, setIntercomMessageOpen } from '~context/GlobalContext/actions';

/**
 * You MUST set up the `alephbeta-gatsby-plugin-intercom` plugin before using this component.
 * This component assumes that `window.Intercom('boot')` has already been called,
 *   and `window.Intercom` is available for use.
 * This is not a true "component" in a proper React sense; it's just a binder of hooks-supplied functions to an external event handlers.
 */
const IntercomWatcher = () => {
  const { dispatch } = useGlobalStore();

  const dispatchSetIntercomMessageOpened = useCallback(
    () => dispatch(setIntercomMessageOpen(true)),
    [dispatch, setIntercomMessageOpen]
  );
  const dispatchSetIntercomMessageClosed = useCallback(
    () => dispatch(setIntercomMessageOpen(false)),
    [dispatch, setIntercomMessageOpen]
  );
  const dispatchSetIntercomInitialized = useCallback(
    () => dispatch(setIntercomInitialized(true)),
    [dispatch, setIntercomInitialized]
  );

  useEffect(() => {
    window.Intercom('onShow', dispatchSetIntercomMessageOpened);
    window.Intercom('onHide', dispatchSetIntercomMessageClosed);
    dispatchSetIntercomInitialized();
  }, []);

  return null;
};

IntercomWatcher.propTypes = {};

export default IntercomWatcher;
